
:root {
  --primary-color: #45a049;
  --secondary-color: #8B0000;
  --background-color: #f4f4f4;
  --text-color: #333;
  --border-color: #ddd;
  --surface-color: #2C2C2C;
  --text-secondary-color: #B0B0B0;
  --hover-color: #FF725C;
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.App-header {
  background-color: var(--surface-color);
  color: var(--text-color);
  text-align: center;
  padding: 1rem;
  margin-bottom: 2rem;
}

h1, h2, h3 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.workshop-selector {
  margin-bottom: 2rem;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--surface-color);
  color: var(--text-color);
}

.workshop-visualizer {
  background-color: var(--surface-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  padding: 2rem;
}

.workshop-details {
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.expandable-section {
  margin-bottom: 2rem;
}

.expandable-section h3 {
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

.expandable-section ul {
  list-style-type: none;
  padding: 0;
}

.expandable-section li {
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
}

.item-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--surface-color);
  cursor: pointer;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  color: var(--secondary-color);
  margin-right: 10px;
}

.expanded-content {
  padding: 1rem;
  border-top: 1px solid var(--border-color);
  background-color: var(--surface-color);
}

.expanded-content p {
  margin: 0.5rem 0;
}

.suggest-ideas-step,
.suggest-participants-step,
.suggest-features-step {
  margin-top: 2rem;
}

.suggest-ideas-step ul,
.suggest-participants-step ul {
  list-style-type: none;
  padding: 0;
}

.suggest-ideas-step li,
.suggest-participants-step li {
  background-color: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.suggest-ideas-step button,
.suggest-participants-step button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.suggest-ideas-step button:hover,
.suggest-participants-step button:hover {
  background-color: var(--hover-color);
}

.suggest-participants-step button:disabled {
  background-color: #444;
  cursor: not-allowed;
}